import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import "./styles.css";
import Title from "./Title";
import SkillsList from "./SkillsList"; // Import the SkillsList
import useCursorEffect from "./useCursorEffect"; // Import the custom hook

function Skills() {
  const { hoveredLink, handleLinkHover } = useCursorEffect();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div id="skills">
      <div className="header-title-container">
        <Title text="Web Development Skills" />
      </div>
      <div className={`skills-container ${hoveredLink ? "link-hovered" : ""}`}>
        {SkillsList.map((skill, index) => (
          <div
            className="skill-box"
            key={index}
            onMouseEnter={() => handleLinkHover(true)}
            onMouseLeave={() => handleLinkHover(false)}
          >
            <div className="skill-logo">
              <img src={skill.image} alt={skill.alt} title={skill.title} />
            </div>
            <h3>{skill.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Skills;
