import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import Title from "./Title";
import gitHubImage from "./assets/githubLogo.png";
import openImage from "./assets/share.png";
import projectList from "./projectList";
import useCursorEffect from "./useCursorEffect"; // Import the custom hook

function Portfolio() {
  const { hoveredLink, handleLinkHover } = useCursorEffect();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div id="portfolio">
      <Title text="PORTFOLIO: CODED FROM SCRATCH" />
      <div
        className={`portfolio-container cursor-effect ${
          hoveredLink ? "link-hovered" : ""
        }`}
      >
        {projectList.map((project, index) => (
          <div
            className="project-box"
            key={index}
            onMouseEnter={() => handleLinkHover(true)}
            onMouseLeave={() => handleLinkHover(false)}
          >
            <h5>{project.name}</h5>
            <img
              className="project-image-border"
              src={project.image}
              alt={project.name}
            />
            <p>{project.techStack}</p>
            <div className="link-icon">
              <a
                href={project.githubLink}
                target="_blank"
                rel="noreferrer"
                title="GitHub"
              >
                <img src={gitHubImage} alt="View on GitHub" />
              </a>
              <a
                href={project.liveLink}
                target="_blank"
                rel="noreferrer"
                title="Live Link"
              >
                <img src={openImage} alt="View Live" />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Portfolio;
