const SkillsList = [
  {
    name: "ReactJS",
    image: require("./assets/react.png"),
    alt: "React",
    title: "React Js",
  },
  {
    name: "HTML5",
    image: require("./assets/html5.png"),
    alt: "HTML 5",
    title: "HTML5",
  },
  {
    name: "CSS",
    image: require("./assets/css3.png"),
    alt: "CSS",
    title: "CSS",
  },
  {
    name: "Javascript",
    image: require("./assets/javascript.png"),
    alt: "Javascript",
    title: "Javascript",
  },
  {
    name: "Tailwind CSS",
    image: require("./assets/tailwind.png"),
    alt: "Tailwind CSS",
    title: "Tailwind CSS",
  },
  {
    name: "Chakra UI",
    image: require("./assets/chakra.png"),
    alt: "Chakra UI",
    title: "Chakra UI",
  },
  {
    name: "GitHub",
    image: require("./assets/github.png"),
    alt: "GitHub",
    title: "GitHub",
  },
  {
    name: "Git",
    image: require("./assets/git.png"),
    alt: "Git",
    title: "Git",
  },
  {
    name: "Wordpress",
    image: require("./assets/wordpress.png"),
    alt: "Wordpress",
    title: "Wordpress",
  },
  {
    name: "JQuery",
    image: require("./assets/jquery.png"),
    alt: "JQuery",
    title: "JQuery",
  },
  {
    name: "Google",
    image: require("./assets/google.png"),
    alt: "Google Workspace",
    title: "Google Workspace",
  },
  {
    name: "Zoho",
    image: require("./assets/zoho.png"),
    alt: "Zoho Workspace",
    title: "Zoho Workspace",
  },
  // I will add more skill objects later
];

export default SkillsList;
