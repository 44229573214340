import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import "./styles.css";
import {
  HiArrowUpOnSquareStack,
  HiCommandLine,
  HiFolder,
  HiEnvelope,
} from "react-icons/hi2";

function Footer() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const handleLinkClick = (event, sectionId) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <footer className="footer">
      <div className="footer-icons">
        <a href="home" onClick={(e) => handleLinkClick(e, "home")} title="Home">
          <h3>
            <HiArrowUpOnSquareStack />
          </h3>
        </a>
        <a
          href="skills"
          onClick={(e) => handleLinkClick(e, "skills")}
          title="My Dev Skills"
        >
          <h3>
            <HiCommandLine />
          </h3>
        </a>
        <a
          href="portfolio"
          onClick={(e) => handleLinkClick(e, "portfolio")}
          title="My Portfolio"
        >
          <h3>
            <HiFolder />
          </h3>
        </a>
        <a
          href="contact"
          onClick={(e) => handleLinkClick(e, "contact")}
          title="Contact Me"
        >
          <h3>
            <HiEnvelope />
          </h3>
        </a>
      </div>
      <p>&copy; {new Date().getFullYear()} Ola White. | My Blog -  <a href="https://eventideahub.com/" target="_blank">Event Idea Hub</a></p> 
    </footer>
  );
}

export default Footer;
