import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import "./styles.css";
import gitHubImage from "./assets/githubLogo.png";
import twitterImage from "./assets/twitter-xLogo.png";
import linkedInImage from "./assets/linkedinLogo.png";
import frontEndMentorImage from "./assets/frontend-mentorLogo.png";
import JustHeader from "./JustHeader";

function Body() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div id="home">
      <JustHeader />
      <div className="body-container">
        <div className="left-box">
          <h3 className="body_para">
            <span class="wave">👋</span> Hello, World!
          </h3>
          <h1 className="name-text">I'm Ola White</h1>
          <h3>I'm a Front-End Developer & Wordpress Expert</h3>
          <br />
          <div className="buttons">
            <a href="#contact" className="button">
              Say Hello
            </a>
            <a href="#portfolio" className="button-2">
              My Portfolio
            </a>
          </div>
          <div className="body_para">
            <p>
              Current: Software Engineer at{" "}
              <a href="https://laylet.com" target="_blank" rel="noreferrer">
                Laylet Techs Ltd.
              </a>
            </p>
            <p>
              Previous: Head of IT{" "}
              <a
                href="https://twitter.com/oalnigeria"
                target="_blank"
                rel="noreferrer"
              >
                @OALNigeria
              </a>
            </p>
            <p>
              Previous: Info. Tech. Manager{" "}
              <a
                href="https://twitter.com/oalnigeria"
                target="_blank"
                rel="noreferrer"
              >
                @HURILAWS
              </a>
            </p>
            <div className="socials">
              <div className="socialImages">
                <a
                  href="https://github.com/whitesoftx/"
                  target="_blank"
                  rel="noreferrer"
                  title="GitHub"
                >
                  <img src={gitHubImage} alt="GitHub" />
                </a>
              </div>
              <div className="socialImages">
                <a
                  href="https://twitter.com/olawhite/"
                  target="_blank"
                  rel="noreferrer"
                  title="Twitter"
                >
                  <img src={twitterImage} alt="Twitter" />
                </a>
              </div>
              <div className="socialImages">
                <a
                  href="https://www.linkedin.com/in/olawhite/"
                  target="_blank"
                  rel="noreferrer"
                  title="LinkedIn"
                >
                  <img src={linkedInImage} alt="LinkedIn" />
                </a>
              </div>
              <div className="socialImages">
                <a
                  href="https://www.frontendmentor.io/profile/whitesoftx"
                  target="_blank"
                  rel="noreferrer"
                  title="Frontend Mentor"
                >
                  <img src={frontEndMentorImage} alt="Frontend Mentor" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="right-box"></div>
      </div>
    </div>
  );
}

export default Body;
