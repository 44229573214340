import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import PropTypes from "prop-types";
import "./styles.css";

function Title({ text }) {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div className="header-title-container">
      <h4 className="header-title">{text}</h4>
    </div>
  );
}

Title.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Title;
