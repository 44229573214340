import React, { useEffect } from "react";
import "./components/styles.css";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import Skills from "./components/Skills";
import Portfolio from "./components/Portfolio";
import WordpressProjects from "./components/WordpressProjects";
import Body from "./components/Body";
import ContactForm from "./components/ContactForm";
import ReactGA from "react-ga4";
import useCursorEffect from "./components/useCursorEffect"; // Import the custom hook

function App() {
  const TRACKING_ID = "G-5G95X13KBV";
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  // Use the custom hook
  const { hoveredLink, handleLinkHover } = useCursorEffect();

  return (
    <div className={`app cursor-effect ${hoveredLink ? "link-hovered" : ""}`}>
      <div className="cursor-follower"></div>
      <div className="App">
        <Navigation />
        <Body hoveredLink={hoveredLink} handleLinkHover={handleLinkHover} />
        <Skills hoveredLink={hoveredLink} handleLinkHover={handleLinkHover} />
        <Portfolio
          hoveredLink={hoveredLink}
          handleLinkHover={handleLinkHover}
        />
        <WordpressProjects
          hoveredLink={hoveredLink}
          handleLinkHover={handleLinkHover}
        />
        <ContactForm
          hoveredLink={hoveredLink}
          handleLinkHover={handleLinkHover}
        />
        <Footer hoveredLink={hoveredLink} handleLinkHover={handleLinkHover} />
      </div>
    </div>
  );
}

export default App;
