import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import "./styles.css";
import logoImage from "./logo.png";
import { HiHome } from "react-icons/hi2";
import useCursorEffect from "./useCursorEffect"; // Import the custom hook

function Navigation() {
  const { hoveredLink, handleLinkHover } = useCursorEffect();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const handleLinkClick = (event, sectionId) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <nav
      className={`navbar cursor-effect ${hoveredLink ? "link-hovered" : ""}`}
      onMouseEnter={() => handleLinkHover(true)}
      onMouseLeave={() => handleLinkHover(false)}
    >
      <div className="logo">
        <img src={logoImage} alt="Ola White" />
      </div>
      <ul className="menu">
        <li>
          <a href="home" onClick={(e) => handleLinkClick(e, "home")}>
            <h2>
              <HiHome />
            </h2>
          </a>
        </li>
        <li>
          <a href="skills" onClick={(e) => handleLinkClick(e, "skills")}>
            My Dev Skills
          </a>
        </li>
        <li>
          <a href="portfolio" onClick={(e) => handleLinkClick(e, "portfolio")}>
            My Portfolio
          </a>
        </li>
        <li>
          <a href="contact" onClick={(e) => handleLinkClick(e, "contact")}>
            Contact Me
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Navigation;
