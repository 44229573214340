import React, { useRef, useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import "./Form.css";
import { FaPhone, FaSquareWhatsapp, FaTelegram } from "react-icons/fa6";
import ReactGA from "react-ga4";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  message: Yup.string().required("Message is required"),
});

const ContactForm = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const form = useRef();
  const [isMessageSent, setIsMessageSent] = useState(false);

  const sendEmail = (values, actions) => {
    emailjs
      .sendForm(
        // "service_uxr6ox9",
        // "template_nn0nl4l",
        // form.current,
        // "d1rtjX6myZGfQP9Gm"
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
          actions.setSubmitting(false);
          actions.resetForm();
          setIsMessageSent(true); // Set the state to show the "Thank you" message
        },
        (error) => {
          console.log(error.text);
          actions.setSubmitting(false);
        }
      );
  };

  return (
    <div id="contact">
      <div className="form-flex-container">
        <div className="form-left-box">
          <h2>I am open to work!</h2>
          <p>
            I'm currently open to various opportunities and collaborations.
            Whether you have a freelance project, a part-time role, or a
            full-time job offer, I would love to hear from you.
          </p>
          <p>
            <strong>Quick Contacts</strong>
          </p>
          <h1>
            <a href="tel:2348056159604" target="_blank" rel="noreferrer">
              <FaPhone className="form-social-connect" />
            </a>
            <a
              href="https://wa.me/2348056159604"
              target="_blank"
              rel="noreferrer"
            >
              <FaSquareWhatsapp className="form-social-connect" />
            </a>
            <a href="https://t.me/whitesoftx" target="_blank" rel="noreferrer">
              <FaTelegram />
            </a>
          </h1>
        </div>
        <div className="form-right-box">
          <h2>Email Me</h2>
          {isMessageSent ? (
            <p className="sent-message">
              Thanks for reaching out.
              <br /> I will get back to you as soon as possible.
            </p>
          ) : null}
          <Formik
            initialValues={{ name: "", email: "", message: "" }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => sendEmail(values, actions)}
          >
            {({ isSubmitting }) => (
              <Form ref={form}>
                <div>
                  <label htmlFor="name">Name</label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter Name"
                  />
                  <ErrorMessage name="name" component="div" />
                </div>
                <div>
                  <label htmlFor="email">Email</label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter Email"
                  />
                  <ErrorMessage name="email" component="div" />
                </div>
                <div>
                  <label htmlFor="message">Message</label>
                  <Field
                    as="textarea"
                    id="message"
                    name="message"
                    rows="5"
                    placeholder="Enter Message"
                  />
                  <ErrorMessage name="message" component="div" />
                </div>
                <button type="submit" disabled={isSubmitting}>
                  Send Message
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
