import { useState, useEffect } from "react";

const useCursorEffect = () => {
  const [hoveredLink, setHoveredLink] = useState(false);

  const handleLinkHover = (isHovered) => {
    setHoveredLink(isHovered);
  };

  useEffect(() => {
    const cursorFollower = document.querySelector(".cursor-follower");
    const links = document.querySelectorAll("a");

    const handleMousemove = (event) => {
      const { clientX, clientY } = event;
      cursorFollower.style.left = `${clientX}px`;
      cursorFollower.style.top = `${clientY}px`;
    };

    document.addEventListener("mousemove", handleMousemove);

    links.forEach((link) => {
      link.addEventListener("mouseenter", () => handleLinkHover(true));
      link.addEventListener("mouseleave", () => handleLinkHover(false));
    });

    return () => {
      document.removeEventListener("mousemove", handleMousemove);
      links.forEach((link) => {
        link.removeEventListener("mouseenter", () => handleLinkHover(true));
        link.removeEventListener("mouseleave", () => handleLinkHover(false));
      });
    };
  }, []);

  return { hoveredLink, handleLinkHover };
};

export default useCursorEffect;
