const projectList = [
  {
    name: "Muslim Nuggets",
    description: "Muslim Nuggets",
    techStack: "ReactJS, Tailwind CSS, Firebase",
    githubLink: "#",
    liveLink: "https://muslimnuggets.com/",
    image: require("./projects/muslimnuggets.png"),
  },
  {
    name: "Voice to Text Transcriber",
    description: "Ola White's Projects on Github",
    techStack: "HTML/CSS/JS, RecorderJS, AssemblyAI",
    githubLink: "#",
    liveLink: "#",
    image: require("./projects/voice-2-text.png"),
  },

  {
    name: "CSV Files Merger",
    description: "Ola White's Projects on Github",
    techStack: "HTML/CSS/JS, Papa Parse API",
    githubLink: "#",
    liveLink: "#",
    image: require("./projects/csv-files-merger.png"),
  },
  {
    name: "Audio Transcriber",
    description: "Ola White's Projects on Github",
    techStack: "HTML/CSS/JS, AssemblyAI API",
    githubLink: "#",
    liveLink: "#",
    image: require("./projects/audio-transcriber.png"),
  },
  {
    name: "To Do App - Bootcamp",
    description: "Ola White's Projects on Github",
    techStack: "NodeJS, ExpressJS, MongoDB, CSS, ++",
    githubLink: "https://github.com/whitesoftx/ToDo-App",
    liveLink: "https://olawhite-todo-app.onrender.com/",
    image: require("./projects/todo.png"),
  },
  {
    name: "Biccas Digital Agency",
    description: "Ola White's Projects on Github",
    techStack: "HTML, CSS, Javascript",
    githubLink: "https://github.com/whitesoftx/biccas",
    liveLink: "https://whitesoftx.github.io/biccas/",
    image: require("./projects/bicas.png"),
  },
  {
    name: "Zuri Team - Bootcamp",
    description: "Ola White's Projects on Github",
    techStack: "HTML, CSS, Javascript",
    githubLink:
      "https://github.com/whitesoftx/zuri-landing-pages/tree/main/team",
    liveLink: "https://whitesoftx.github.io/zuri-landing-pages/team/index.html",
    image: require("./projects/zuri-team.png"),
  },

  {
    name: "Google Newsletter",
    description: "Ola White's Projects on Github",
    techStack: "HTML, CSS, Javascript",
    githubLink: "https://github.com/whitesoftx/exercise-google-newsletter",
    liveLink: "https://whitesoftx.github.io/exercise-google-newsletter/",
    image: require("./projects/google-newsletter.png"),
  },
  {
    name: "Google Keep Clone",
    description: "Ola White's Projects on Github",
    techStack: "ReactJS, HTML, CSS, Javascript & more",
    githubLink: "https://github.com/whitesoftx/React-Keeper-App",
    liveLink: "https://react-keeper-app-six.vercel.app/",
    image: require("./projects/keeper.png"),
  },
  {
    name: "Zuri Website - Bootcamp",
    description: "Ola White's Projects on Github",
    techStack: "HTML, CSS, Javascript",
    githubLink:
      "https://github.com/whitesoftx/zuri-landing-pages/tree/main/training",
    liveLink:
      "https://whitesoftx.github.io/zuri-landing-pages/training/index.html",
    image: require("./projects/zuri-home.png"),
  },
  // {
  //   name: "Fylo - Frontend Mentor",
  //   description: "Ola White's Projects on Github",
  //   techStack: "HTML, CSS, Javascript",
  //   githubLink:
  //     "https://github.com/whitesoftx/fylo-landing-page-with-two-column-layout",
  //   liveLink:
  //     "https://whitesoftx.github.io/fylo-landing-page-with-two-column-layout/",
  //   image: require("./projects/fylo.png"),
  // },
  {
    name: "Zuri Talent - Bootcamp",
    description: "Ola White's Projects on Github",
    techStack: "HTML, CSS, Javascript",
    githubLink:
      "https://github.com/whitesoftx/zuri-landing-pages/tree/main/talent",
    liveLink:
      "https://whitesoftx.github.io/zuri-landing-pages/talent/index.html",
    image: require("./projects/zuri-talent.png"),
  },
  {
    name: "Huddle - Frontend Mentor",
    description: "Ola White's Projects on Github",
    techStack: "HTML, CSS, Javascript",
    githubLink: "https://github.com/whitesoftx/huddle-landing-page",
    liveLink: "https://whitesoftx.github.io/huddle-landing-page/",
    image: require("./projects/huddle.png"),
  },

  // I will add more projects when available
];

export default projectList;
