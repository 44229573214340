// WordpressProjects.js
import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import "./WordpressProjects.css";
import "./styles.css";
import Title from "./Title";
import useCursorEffect from "./useCursorEffect"; // Import the custom hook

const WordpressProjects = () => {
  const { hoveredLink, handleLinkHover } = useCursorEffect();
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const websites = [
    {
      title: "Slice Media - Nigeria",
      link: "https://slicemedialimited.com/",
      image: "slicemedialimited.png",
    },
    {
      title: "DO Healthcare - UK",
      link: "https://dohealhcare.co.uk",
      image: "dohealhcare.png",
    },
    {
      title: "Accoladens - Canada",
      link: "https://accoladens.net/",
      image: "accoladens.png",
    },
    {
      title: "MyArt Experience - USA",
      link: "https://myartexperience.net/",
      image: "myartexperience.png",
    },
    {
      title: "Dortripat Care - UK",
      link: "https://dortripatcareltd.co.uk",
      image: "dortripatcareltd.png",
    },
    {
      title: "NAPTIP - Nigeria",
      link: "https://www.naptip.gov.ng/",
      image: "naptip.png",
    },
    {
      title: "PSMC - Nigeria",
      link: "https://thepremiermedical.com/",
      image: "thepremiermedical.png",
    },
    {
      title: "PreLoved - Nigeria",
      link: "https://preloved.ng/",
      image: "preloved.png",
    },
    {
      title: "AA Law - Nigeria",
      link: "https://aalawpractice.com/",
      image: "aalawpractice.png",
    },
    {
      title: "NBA HRI - Nigeria",
      link: "https://nbahri.org/",
      image: "nbahri.png",
    },
    {
      title: "SGBV eLearning - Nigeria",
      link: "https://sgbveducation.com/",
      image: "sgbveducation.png",
    },
    {
      title: "Law Hub - Nigeria",
      link: "https://lawhubconsulting.com/",
      image: "lawhubconsulting.png",
    },
    {
      title: "Laylet Techs - Nigeria",
      link: "https://laylet.com/",
      image: "laylet.png",
    },
    {
      title: "TimeOak Hotels - Nigeria",
      link: "https://timeoakhotels.com/",
      image: "timeoakhotels.png",
    },
    {
      title: "News Tap - Nigeria",
      link: "https://newstap.com.ng/",
      image: "newstap.png",
    },
    {
      title: "Fodado - Nigeria",
      link: "https://fodado.com/",
      image: "fodado.png",
    },
    
  ];

  return (
    <div
      className={`wordpress-projects-container cursor-effect ${
        hoveredLink ? "link-hovered" : ""
      }`}
    >
      <Title text="PORTFOLIO: BUILT WITH WORDPRESS" />
      <div className="preview-container">
        {websites.map((website, index) => (
          <div
            key={index}
            className="project-item"
            onMouseEnter={() => {
              handleLinkHover(true);
              setPreviewImage(website.image);
            }}
            onMouseLeave={() => {
              handleLinkHover(false);
              setPreviewImage(null);
            }}
          >
            <a href={website.link} target="_blank" rel="noopener noreferrer">
              {website.title}
            </a>
          </div>
        ))}
        {hoveredLink && previewImage && (
          <div className="website-preview">
            <img
              src={require(`./projects/${previewImage}`)}
              alt={`preview-${previewImage}`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default WordpressProjects;
