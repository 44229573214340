import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import "./styles.css";
import logoImage from "./logo.png";

export default function JustHeader() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div className="just-header">
      <img src={logoImage} alt="Ola White" />
    </div>
  );
}
